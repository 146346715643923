// src/routes/ThemeRoutes.js

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
import PrivateRoute from './PrivateRoute'; // Import your PrivateRoute component

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));

/***** Functional  Pages ****/
const Bill = Loadable(lazy(() => import('../views/bill/BillPage')));
const Expense = Loadable(lazy(() => import('../views/expense/ExpensePage')));
const Income = Loadable(lazy(() => import('../views/income/IncomePage')));
const Goal = Loadable(lazy(() => import('../views/goal/GoalPage')));
const Profile = Loadable(lazy(() => import('../views/profile/Profile')));
const Dashboard1 = Loadable(lazy(() => import('../views/dashboards/v1/Dashboard')));
const FeatureOrBug = Loadable(lazy(() => import('../views/feature/BugFeatureReportForm')));
const Budget = Loadable(lazy(() => import('../views/budget/BudgetPage')));

/***** Apps ****/
const Notes = Loadable(lazy(() => import('../views/apps/notes/Notes')));
const Calendar = Loadable(lazy(() => import('../views/apps/calendar/CalendarApp')));
const Chat = Loadable(lazy(() => import('../views/apps/chat/Chat')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const Register = Loadable(lazy(() => import('../views/auth/Register')));
const Login = Loadable(lazy(() => import('../views/auth/Login')));
const EmailConfirmationPage = Loadable(lazy(() => import('../views/auth/EmailConfirmationPage')));
const PasswordResetPage = Loadable(lazy(() => import('../views/auth/PasswordResetPage')));
const ForgotPasswordPage = Loadable(lazy(() => import('../views/auth/ForgotPasswordPage')));

/*****Routes******/
const ModeHandler = Loadable(lazy(() => import('./ModeHandler')));

const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', name: 'Demographical', exact: true, element: <PrivateRoute element={<Dashboard1 />} /> },

      { path: '/apps/notes', name: 'Notes', exact: true, element: <PrivateRoute element={<Notes />} requiredSubscription="premium" /> },
      { path: '/apps/calendar', name: 'Calendar', element: <PrivateRoute element={<Calendar />} requiredSubscription="premium" /> },
      { path: '/apps/chat', name: 'Chat', element: <PrivateRoute element={<Chat />} requiredSubscription="premium" /> },
      
      { path: '/bill', name: 'Bill', element: <PrivateRoute element={<Bill />} /> },
      { path: '/expense', name: 'Expense', element: <PrivateRoute element={<Expense />}  /> },
      { path: '/income', name: 'Income', element: <PrivateRoute element={<Income />} /> },
      { path: '/goal', name: 'Goal', element: <PrivateRoute element={<Goal />}   /> },
      { path: '/profile', name: 'Profile', element: <PrivateRoute element={<Profile />} /> },
      { path: '/budget', name: 'Budget', element: <PrivateRoute element={<Budget />} /> },
      { path: '/contact-us', name: 'Contact Us', element: <PrivateRoute element={<FeatureOrBug />} /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'register', element: <Register /> },
      { path: 'login', element: <Login /> },
      { path: 'resetPassword', element: <PasswordResetPage /> },
      { path: 'forgetPassword', element: <ForgotPasswordPage /> },
      { path: 'verifyEmail', element: <EmailConfirmationPage /> },
      { path: '*', element: <ModeHandler /> },
    ],
  },
];

export default ThemeRoutes;
