import axios from 'axios';
import { createSlice } from '@reduxjs/toolkit';
import user2 from '../../../assets/images/users/AI.png';


const initialState = {
  chats: [],
  chatContent: 1
};

export const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getChats: (state, action) => {
      state.chats = action.payload;
    },
    SearchChat: (state, action) => {
      state.chatSearch = action.payload;
    },
    SelectChat: (state, action) => {
      state.chatContent = action.payload;
    },
    sendMsg: {
      reducer: (state, action) => {
        const { id, chatMsg } = action.payload;
        state.chats = state.chats.map((chat) => {
          if (chat.id === id) {
            // Ensure chatHistory is an array
            if (!Array.isArray(chat.chatHistory)) {
              chat.chatHistory = [];
            }
    
            // Ensure chatHistory has at least one object
            if (chat.chatHistory.length === 0) {
              chat.chatHistory.push({});
            }
    
            // Ensure the second element of chatHistory is an object
            if (typeof chat.chatHistory[0] !== 'object') {
              chat.chatHistory[0] = {};
            }
    
            // Ensure the [1] index is an object with a 'to' array
            if (!chat.chatHistory[0][1]) {
              chat.chatHistory[0][1] = { to: [] };
            }
    
            // Add the new message to the 'to' array
            chat.chatHistory[0][1].to.push(chatMsg);
    
            return { ...chat };
          }
          return chat;
        });
      },
      prepare: (id, chatMsg) => ({
        payload: { id, chatMsg },
      }),
    },
  },
});

export const { SearchChat, getChats, sendMsg, SelectChat } = ChatSlice.actions;

export const fetchChats = () => async (dispatch) => {
  try {
    // const response = await axios.get(`${API_URL}`);
    dispatch(getChats([
      {
        id: 1,
        name: 'Maria Hernandez',
        status: 'away',
        thumb: user2,
        recent: true,
        excerpt: 'Doctor',
        chatHistory: [
          {
            0: {
              from: ['The sky is clear', 'How was the math test?'],
              to: ['She always speaks to him in a loud voice.'],
            },
            1: {
              from: [
                'The memory we used to share is no longer coherent.',
                'The mysterious diary records the voice.',
                'The old apple revels in its authority.',
              ],
              to: ['Please wait outside of the house.'],
            },
          },
        ],
      },
      {
        id: 3,
        name: 'Maria Hernandez',
        status: 'away',
        thumb: user2,
        recent: true,
        excerpt: 'Doctor',
        chatHistory: [
          {
            0: {
              from: ['The sky is clear', 'How was the math test?'],
              to: ['She always speaks to him in a loud voice.'],
            },
            
          },
        ],
      },
    ]));
  } catch (err) {
    throw new Error(err);
  }
};






export default ChatSlice.reducer;
