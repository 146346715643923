import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';

// Utility functions to check internet status
const checkInternetConnection = () => navigator.onLine;

const checkInternetSpeed = () => {
  const startTime = new Date().getTime();
  return new Promise((resolve) => {
    const img = new Image();
    img.src = 'https://via.placeholder.com/1000x1000.png'; // a large image to test speed
    img.onload = () => {
      const endTime = new Date().getTime();
      const duration = endTime - startTime;
      resolve(duration > 5000); // assuming 3 seconds is the threshold for slow connection
    };
  });
};

const InternetStatus = () => {
  const [isNoInternet, setIsNoInternet] = useState(false);
  const [isSlowInternet, setIsSlowInternet] = useState(false);

  useEffect(() => {
    const updateInternetStatus = async () => {
      setIsNoInternet(!checkInternetConnection());
      if (navigator.onLine) {
        const isSlow = await checkInternetSpeed();
        setIsSlowInternet(isSlow);
      }
    };

    window.addEventListener('online', updateInternetStatus);
    window.addEventListener('offline', updateInternetStatus);

    updateInternetStatus();

    return () => {
      window.removeEventListener('online', updateInternetStatus);
      window.removeEventListener('offline', updateInternetStatus);
    };
  }, []);

  return (
    <>
      <Modal isOpen={isNoInternet} centered>
        <ModalHeader>No Internet Connection</ModalHeader>
        <ModalBody style={{ textAlign: 'center' }}>
          <p>Please check your internet connection.</p>
        </ModalBody>
      </Modal>

      <Modal isOpen={isSlowInternet && !isNoInternet} centered>
        <ModalHeader>Slow Internet Connection</ModalHeader>
        <ModalBody style={{ textAlign: 'center' }}>
          <p>Your internet connection is slow. Please wait...</p>
          <Spinner style={{ width: '3rem', height: '3rem' }} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default InternetStatus;
