import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveDataOnRealtimeDb, getDataOnRealtimeDb, updateDataOnRealtimeDb, deleteDataOnRealtimeDb } from '../../../utils/firebase';

const initialState = {
  notes: [],
  notesContent: 1,
  noteSearch: '',
};

export const fetchNotes = createAsyncThunk('notes/fetchNotes', async (userId) => {
  try {
    const response = await getDataOnRealtimeDb(`users/${userId}/notes`);
    if (response == null) {
      return
    }
    const notesArray = Object.keys(response).map(key => ({
      key,
      id: key, // Assign the key as the note id
      ...response[key] // Spread the note properties
    }));
    return notesArray;
  } catch (error) {
    console.log('Error fetching notes:', error.message);
    return [];
  }
});

export const saveNote = createAsyncThunk('notes/saveNote', async ({ userId, note }) => {
  const { key, ...noteToUpdate } = note;
  if (key != null || key != undefined) {
    await updateDataOnRealtimeDb(`users/${userId}/notes`, key, noteToUpdate);
    return note;
  }
  else {
    await saveDataOnRealtimeDb(`users/${userId}/notes`, note);
    return note;
  }

});


export const deleteNote = createAsyncThunk('notes/deleteNote', async ({ userId, note }) => {
  await deleteDataOnRealtimeDb(`users/${userId}/notes/`, note.key);
  return note;
});

const NotesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    getNotes: (state, action) => {
      state.notes = action.payload;
    },
    SearchNotes: (state, action) => {
      state.noteSearch = action.payload;
    },
    SelectNote: (state, action) => {
      state.notesContent = action.payload;
    },
    addNote: {
      reducer: (state, action) => {
        state.notes.push(action.payload);
      },
      prepare: (id, title, color) => ({
        payload: { id, title, color, date: new Date().toDateString() },
      }),
    },
    UpdateNote: {
      reducer: (state, action) => {
        const { id, field, value } = action.payload;
        const dnote = state.notes.find((note) => note.id === id);
        if (dnote) {
          dnote[field] = value;
        }
      },
      prepare: (id, field, value) => ({
        payload: { id, field, value },
      }),
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.notes = action.payload || [];
      })
      .addCase(saveNote.fulfilled, (state, action) => {
        const existingNoteIndex = state.notes.findIndex(note => note.id === action.payload.id);
        if (existingNoteIndex !== -1) {
          // Update existing note if found
          state.notes[existingNoteIndex] = action.payload;
        } else {
          // Otherwise, push the new note
          state.notes.push(action.payload);
        }
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        const index = state.notes.findIndex((note) => note.id === action.payload);
        state.notes.splice(index, 1);
      });
  },
});

export const { SearchNotes, getNotes, SelectNote, addNote, UpdateNote } = NotesSlice.actions;
export default NotesSlice.reducer;
